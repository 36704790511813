import { Link } from "react-router-dom";
import logo from "../Misc/Images/nimecLogo.png";

const Logo = () => {
  return (
    <div className="logo-cont">
      <Link to="/">
        <img src={logo} className="logo" alt="logo" />
      </Link>
    </div>
  );
};

export default Logo;
