//Home

const clients = [
  {
    name: "Abundant Life Assembly",
    municipalAgg: false,
    organization: "Misc",
    references: [],
  },
  {
    name: "Amboy",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Arlington Heights.",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Aroma Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Barrington",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Bartlett Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Beecher",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Belvidere",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Belvidere Township",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Bensenville Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Bourbonnais",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Bourbonnais Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Bradley",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Braidwood",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Brookfield",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Brookfield-Riverside Water Comm.",
    municipalAgg: false,
    organization: "Water Commission",
    references: [],
  },
  {
    name: "Buffalo Grove",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Calumet City",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Carol Stream",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Carpentersville",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Cary",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Channahon",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Chicago Hts",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Clarendon Hills",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Coal City",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Coal City Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Countryside",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Countryside Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Covenant Presbyterian Church",
    municipalAgg: false,
    organization: "Misc",
    references: [],
  },
  {
    name: "Crest Hill",
    municipalAgg: true,
    organization: "Municipality",
    references: [
      {
        title: "Mayor",
        quote:
          "NIMEC has been a tremendous resource in passing the electric aggregation referendum. His assistance and knowledge during this process has been invaluable. I would not hesitate in using NIMEC in the future if considering electrical aggregation.",
      },
    ],
  },
  {
    name: "Crete",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Crystal Lake",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Da Vinci Waldorf School",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Darien",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Deerfield",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Dixon",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Du-Comm",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Dundee Twp Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Dwight",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "East Dundee Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Eisenhower Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Ela Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Elburn",
    municipalAgg: true,
    organization: "Municipality",
    references: [
      {
        title: "City Administrator",
        quote:
          "NIMEC is a very professional company to work with. They walked the Village through the aggregation process and met personally with the Village along the way. Their promptness, organization and detail were exemplary.",
      },
    ],
  },
  {
    name: "Elk Grove Village",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Elwood",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Flossmoor",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Forest Park",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Fountaindale Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Fox River Grove",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Fox River Grove Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Frankfort Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Franklin Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Gardner",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Gilberts",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Glen Ellyn",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Glencoe",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Glendale Heights",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Glenside Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Glenview",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Glenwood",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Grundy County",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Gurnee",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Hanover Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Harvard",
    municipalAgg: false,
    organization: "Municipality",
    references: [
      {
        title: "City Administrator",
        quote:
          "We had a great experience with NIMEC. They first introduced us to aggregation, then led us through the entire process. Our legal costs were kept to a minimum by using NIMEC's standardized documents. NIMEC saved our staff significant amounts of time and I'd definitely use them again.",
      },
    ],
  },
  {
    name: "Harvard Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Harwood Heights",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Hazel Crest",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Highland Park",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Hinsdale",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Hoffman Estates",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Homewood",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Homewood-Flossmoor Park",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Hopkins Park",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Indian Prairie Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Indian Trails Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Inner City Impact",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Island Lake",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Itasca",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Joliet Montessori School",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Kankakee",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Kankakee County",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "La Grange",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "La Grange Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "LaGrange Library District",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Lake Barrington",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lake in Hills Sanitary",
    municipalAgg: false,
    organization: "Water Commission",
    references: [],
  },
  {
    name: "Lake in Hills Sanitary District",
    municipalAgg: false,
    organization: "Water Commission",
    references: [],
  },
  {
    name: "Lake Villa Library District",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Lake Zurich",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "LaSalle",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lee County Council of Aging",
    municipalAgg: false,
    organization: "Misc",
    references: [],
  },
  {
    name: "Libertyville",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lincolnwood Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Lindenhurst",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lisle",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lisle Woodridge Fire",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Lockport",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lockport Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Lombard",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Long Grove Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Manhattan",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Manteno",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Marengo",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Mendota",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Minonk",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Minooka",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Mokena",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Mokena Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Momence",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Montgomery",
    municipalAgg: true,
    organization: "Municipality",
    references: [
      {
        title: "Public Works Director",
        quote:
          "Thanks for your work in organizing the NIMEC communities to band together. You have led us to a monumental change in how governments work for the common good, and provided a real, tangible benefit to citizens across the state.",
      },
    ],
  },
  {
    name: "Morris",
    municipalAgg: true,
    organization: "Municipality",
    references: [
      {
        title: "Mayor",
        quote:
          " When considering the intimidating language in the state statute, the support and professional assistance received from NIMEC made the Electric Aggregation process simple. From the 'boilerplate' forms provided to the personal attendance by a professional consultant at our Public Hearings, NIMEC was there to walk the City Council through the process. In these tough economic times, NIMEC is one of the best things to happen to the citizens of the City of Morris in a long, long time!",
      },
    ],
  },
  {
    name: "Morris Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Morton Grove",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Mundelein",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Mundelein Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "New Lenox",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Norridge",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "North Aurora",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "North Chicago",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "North Moraine Water District",
    municipalAgg: false,
    organization: "Water Commission",
    references: [],
  },
  {
    name: "North Riverside Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Northlake",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Orland Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Oswego",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Inverness Fire Protection District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Palatine Township",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Palos Heights.",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Palos Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Park Forest",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Park Ridge",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Park Ridge Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Pecatonica",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Pingree Grove",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Plainfield",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Plato Academy",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Pontiac",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Prairie Grove",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Redick",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "River Grove",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Riverside",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Roselle Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Round Lk Beach",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Schiller Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Shorewood",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "South Barrington",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "South Elgin",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "South Holland",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "St. Anne",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Streamwood",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Sugar Grove",
    municipalAgg: true,
    organization: "Municipality",
    references: [
      {
        title: "Finance Director",
        quote:
          "Once approved by voters, NIMEC provided a sample Plan of Operation and Governance, managed the bid process, and assisted in the negotiation of the contract. There were a few small glitches, as with any new program, and NIMEC was ready, willing, and more than able to handle those as they came up. Overall, NIMEC was an important asset to have throughout the entire process.",
      },
    ],
  },
  {
    name: "Sugar Grove Library District",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Sun River Terrace",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Sycamore",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Tinley Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Tinley Park Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Unity Christian Academy",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Villa Park",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Warrenville",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Warrenville Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Wauconda",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wauconda Fire District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "West Chicago",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "West Dundee",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wheaton",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wheaton Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Wilmette",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wilmington",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wonder Lake",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Wood Dale",
    municipalAgg: false,
    organization: "Municipality",
    references: [
      {
        title: "City Administrator",
        quote:
          "The experience working with NIMEC has been smooth sailing; the knowledge base of electric aggregation that was shared with the City was instrumental to the program's success. As this was a big change for the community and a large-scale program, NIMEC was up to the task at hand and always went above and beyond to assist not only City Staff but also residents that inquired about the program.",
      },
    ],
  },
  {
    name: "Wood Dale Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Woodridge",
    municipalAgg: true,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Woodridge Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Worth",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Rolling Meadows",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lyons",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Algonquin Area Public Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Calvin Christian School",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
  {
    name: "Darien Woodridge Fire Protection District",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Evergreen Park Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Glencoe Park District",
    municipalAgg: false,
    organization: "Park District",
    references: [],
  },
  {
    name: "Lake Villa",
    municipalAgg: false,
    organization: "Municipality",
    references: [],
  },
  {
    name: "Lake Zurich Rural FPD",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "New Hope Church",
    municipalAgg: false,
    organization: "Miscellaneous",
    references: [],
  },
  {
    name: "Rutland Dundee FPD",
    municipalAgg: false,
    organization: "Fire District",
    references: [],
  },
  {
    name: "Stickney Forest View Library",
    municipalAgg: false,
    organization: "Library",
    references: [],
  },
  {
    name: "Trinity Lutheran School",
    municipalAgg: false,
    organization: "School",
    references: [],
  },
];

//Home
const missionStatement =
  "NIMEC is a purchasing group of over 170+ municipal and non-profit organizations that help lower member’s utility supply costs by over 20%. Additionally, NIMEC has been helping its members (libraries, schools, fire districts, and park districts) purchase power for their water pumping, street lighting, and natural gas. Due to NIMEC’s unique group purchasing, NIMEC’s retention rate averages 99%, while growing each year. Specializing in municipalities in northern Illinois, NIMEC works with Village Boards to help answer any issues or questions that may arise.\n\nAdditionally, NIMEC is the leading municipal aggregator of electricity in northern Illinois, having managed 100 municipal aggregations with a population base approaching 2,000,000. No one manages more aggregation programs in northern Illinois. Illinois municipalities can now negotiate lower electric rates for its residents’ home bills. In the same way municipalities negotiate community-wide contracts for waste disposal or cable television, NIMEC helps communities purchase power for their residents. NIMEC’s purchasing power of $150 million per year helps our members achieve aggressive rates for their residents.\n\nLastly, NIMEC is assisting with a new opportunity that has opened up for residents in Illinois.  Community Solar, which is backed and incentivized by the state of Illinois, allows for guaranteed 20% savings on the supply of your electric bill, while creating energy from a local solar field (no solar panels on property is required). Some of NIMEC’s suppliers would like to inform all residents about this program. Community Solar is so popular, that there is limited availability, please reach out to Adam Hoover if you have any questions.";

//OurServices
const services = [
  {
    service: "Municipalities",
    synopsis:
      "NIMEC is the leading municipal aggregator of electricity in northern Illinois, having managed 100 municipal aggregations with a population base over 2,000,000. Currently, NIMEC manages more Municipal Aggregation programs than anyone else. Illinois municipalities can now negotiate lower electric rates for its residents’ home bills. In the same way municipalities negotiate community-wide contracts for waste disposal or cable television, NIMEC helps communities purchase power for their residents. NIMEC’s purchasing power of $150 million per year helps our members achieve aggressive rates for their residents.\n\nNIMEC works with municipalities to make the Municipal Aggregation process as simple as possible. NIMEC has prepared and presented at over thousands of village meetings in norther Illinois to take the burden off the village staff. Additionally, NIMEC continually works with the staff of each municipality to answer any questions from residents that arise about the aggregation process.\n\nNIMEC acts as a consultant to the municipality and therefore will never charge the municipality a fee. Additionally, NIMEC does not require contracts with their members. Each organization is free to accept and reject offers as they see fit.",
  },
  {
    service: "Commercial",
    synopsis:
      "What separates NIMEC from other consultants and brokers throughout Illinois is their group purchasing process. Once in the spring, NIMEC bids out it’s $25,000,000 in purchasing power to see which supplier offers the best rate. Unlike others, each member gets their own rate so there is no subsidizing of members, while also receiving the benefits of a group bid. The group purchasing has proven to allow members to receive lower prices than they would on their own.",
  },
  {
    service: "Residential",
    synopsis:
      "A new opportunity has opened up for residents in Illinois.  Community Solar, which is backed and incentivized by the state of Illinois, allows for guaranteed 20% savings on the supply of your electric bill, while creating energy from a local solar field (no solar panels on property is required). Yet, the program is so popular that there is limited availability.",
  },
];

//FAQs
const FAQs = [
  {
    order: 2,
    Q: "What is the ComEd rate?",
    A: "The ComEd rate consists of three portions, two of which are adjusted twice annually, and the third of which can change monthly. to discover the current ComEd rate, visit Plug In Illinois.",
    linkNeeded: true,
    link: "https://pluginillinois.org/FixedRateBreakdownComEd.aspx",
    linkWord: "Plug In Illinois",
  },
  {
    order: 3,
    Q: "What if residents don't want to participate in the program?",
    A: "Residents will receive two opportunities to opt out of the aggregation program. When a new supplier is selected, they will receive a letter with an opt out form and ComEd will also send an opt out form.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 4,
    Q: "Can residents opt out before the program begins then return later to the supplier that won the bid in the aggregation?",
    A: "Yes.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 5,
    Q: "What is the referendum question?",
    A: "“Shall the village/city have the authority to arrange for the supply of electricity for its residential and small commercial retail customers who have not opted out of such a program?",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 6,
    Q: "Are commercial accounts included in the aggregation?",
    A: "Currently, only commercial accounts that use less than 15,000 kWh’s/year are included. This generally includes only smaller commercial accounts. Larger commercial accounts are ineligible and need to negotiate an individual contract apart from the aggregation.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 7,
    Q: "What happens if the power goes out? Whom do residents call?",
    A: "Residents should continue to call ComEd with any service issues. ComEd will still deliver electricity. Residents will simply have a new supplier.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 8,
    Q: "Will residents then receive two separate bills—- one from the deliverer, the other from the supplier?",
    A: "No. Residents will only receive a bill from ComEd. The only difference on the bill is the new lower rate and the new supplier’s name will be listed.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 1,
    Q: "What do residents have to do to be in the aggregation and get the lower rate?",
    A: "All eligible residents are automatically included in the program once it has been approved by voters.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 9,
    Q: "If a resident is getting offers from retail electric suppliers to switch for lower rates. What should they do?",
    A: "If residents switch their electric account to a new supplier prior to the aggregation program, they will not be included in the community aggregation. If a resident switches to a new supplier, and later wants to join the community aggregation, they may (possibly have to pay a termination fee to their current supplier. Residents should review the details of their suppliers’ agreement. There is no fee from the aggregation supplier to join the aggregation.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 10,
    Q: "What happens with ComEd? Won’t they increase charges to keep their profit margins?",
    A: "Residents’ choice of supplier has no impact on ComEd as they only deliver and do not supply the power. ComEd processes the bills for these supplier companies, and passes 100 percent of the revenue back to the company that generates the power. ComEd’s rates are regulated by the ICC, so any increase would have to first be approved by the ICC.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
  {
    order: 11,
    Q: "What if the rate residents in the municipality currently have is lower than the power suppliers’ bids?",
    A: "No action will be taken. The municipality’s residents’ electric accounts will not move to a new supplier. The referendum does not mandate the municipality to switch to a new provider.",
    linkNeeded: false,
    link: null,
    linkWord: null,
  },
];

//savings
const savings = {
  synopsis:
    "Savings for municipalities during municipal aggregation have varied over the 10 years of running programs. NIMEC always checks to see how the aggregation program can help save residents money. However, if that is not possible, NIMEC looks to find a combination of green energy or civic contributions to the municipality.\n\nFor residents, through the use of Community Solar, residents can find 20% savings on their supply portion of their electricity bill, whether or not they are part of a municipal aggregation program. This program allows for residents to find their own savings. To sign up, please use this link.\n\nLastly, for commercial accounts, such as those from municipalities, fire district, park districts, schools, and others, NIMEC has beated the ComEd rate by 27% annually since 2008. To see how much NIMEC can help your organization save, please contact us with a copy of your latest utility bill.",
  link: "https://app.solstice.us/register?r=NIMEC",
  linkWord: "please use this link",
};

//aboutUs
const EEs = [
  {
    name: "David Hoover",
    title: "Founder and Executive Director",
    email: "dhoover@nimec.net",
    phone: "847-392-9300",
    synopsis:
      "David is NIMEC's Founder and Executive Director. He has consulted in the energy industry since 1992, having owned and managed Glenview Consulting Group through 2006. Glenview provided utility consulting services to 200 corporate and municipal clients. David formed NIMEC in 2006 to help his municipal clients take advantage of the savings opportunities presented through Illinois utility deregulation.\n\nDavid holds an MBA from Arizona State University and graduated cum laude with concentration in Business from the University of Colorado.",
    order: 1,
  },
  {
    name: "Chad Oldham",
    title: "Director, Strategic Accounts",
    email: "coldham@nimec.net",
    phone: "847-530-6364",
    synopsis:
      "Chad is a Director of Strategic Accounts at NIMEC. He spends most of his time implementing the municipal aggregation program, working with municipalities and multiple suppliers to ensure a smooth transition.  Chad was previously a teacher for 10+ years. He graduated from Taylor University and has his Masters as well.",
    order: 3,
  },
  {
    name: "Adam Hoover",
    title: "Director, Strategic Accounts",
    email: "ahoover@nimec.net",
    phone: "847-732-2515",
    synopsis:
      "Adam is a Director of Strategic Accounts at NIMEC. He has implemented municipal aggregation deals, Community Solar opportunities, as well as fixed rates with power and natural gas for Fire Districts, Park Districts, schools, and churches.\n\nPreviously Adam was a teacher in Chicago Public Schools for 10 years, as well as a head basketball coach for eight years. He graduated from Taylor University, and got his Masters from Concordia in California. He is an avid Chicago sports fan, and enjoys time with his wife Karen, and 2 yr old dog, Lincoln.",
    order: 2,
  },
];

module.exports = { clients, missionStatement, services, FAQs, savings, EEs };
