import { Link } from "react-router-dom";

const LinkBox = ({ box }) => {
  return (
    <Link
      to={box && box.link}
      className="single-link-box"
      style={{ textDecoration: "none" }}
    >
      <h2> {box && box.text}</h2>
    </Link>
  );
};

export default LinkBox;
