import { convertToUrlLink } from "../../funcs";
import { savings } from "../Misc/Data";
import savingsChart from "../Misc/Images/Nimec Rate Comparison.png";
import "./Savings.css";

const SavingsPage = () => {
  return (
    <div className="savings-page">
      <div className="service-header">Savings</div>
      <div className="savings-synopsis">
        {convertToUrlLink(savings, "synopsis")}
      </div>
      <img className="chart-cont" src={savingsChart}></img>
    </div>
  );
};

export default SavingsPage;
