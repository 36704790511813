import { useState, useEffect } from "react";
import { monitorScreenWidth, getScreenWidth } from "../../funcs";
import { EEs } from "../Misc/Data";
import EECont from "./EECont";
import MobilePage from "./mobile/MobilePage";
import "./AboutUs.css";

const AboutUsPage = () => {
  const [isMobileView, setIsMobileView] = useState(getScreenWidth("max", 65));

  useEffect(() => {
    monitorScreenWidth("max", 65, setIsMobileView);
  }, []);

  const sortedEEs = EEs.sort((a, b) => a.order - b.order);

  return (
    <div className="about-us-page">
      {isMobileView && (
        <h1 className="about-us-mobile-header-mobile">
          Click on a Nimec team member's profile to learn more about them!
        </h1>
      )}
      {sortedEEs.map((ee, idx) =>
        isMobileView ? (
          <MobilePage key={idx} ee={ee} />
        ) : (
          <EECont key={idx} ee={ee} />
        )
      )}
    </div>
  );
};

export default AboutUsPage;
