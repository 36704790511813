import { Document, Page } from "react-pdf";
import carbonNewsletter from "../Misc/Files/Carbon Adjustment.pdf";
import solarOfferingNewsletter from "../Misc/Files/Solar Offering.pdf";

const Newsletter = ({ service, pageNumber, onDocumentLoadSuccess }) => {
  return (
    <Document
      file={
        service?.service === "Residential"
          ? solarOfferingNewsletter
          : carbonNewsletter
      }
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page pageNumber={pageNumber} />
    </Document>
  );
};

export default Newsletter;
