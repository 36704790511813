import { Routes, Route } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import OurServicesPage from "./components/OurServices/OurServicesPage";
import MembershipsPage from "./components/Memberships/MembershipsPage";
import FAQsPage from "./components/FAQs/FAQsPage";
import SavingsPage from "./components/Savings/SavingsPage";
import AboutUsPage from "./components/AboutUs/AboutUsPage";
import EESinglePage from "./components/AboutUs//mobile/EESinglePage";

const RouteInfo = () => {
  const routeOptions = [
    { path: "/", component: <HomePage /> },
    { path: "/home", component: <HomePage /> },
    { path: "/our_services/municipalities", component: <OurServicesPage /> },
    { path: "/our_services/commercial", component: <OurServicesPage /> },
    { path: "/our_services/residential", component: <OurServicesPage /> },
    { path: "/memberships", component: <MembershipsPage /> },
    { path: "/faqs", component: <FAQsPage /> },
    { path: "/savings", component: <SavingsPage /> },
    { path: "/about_us", component: <AboutUsPage /> },
    { path: "/about_us/:id", component: <EESinglePage /> },
  ];

  return (
    <Routes>
      {routeOptions.map((route, idx) => (
        <Route key={idx} exact path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

export default RouteInfo;
