import Service from "./Service";
import "./OurServices.css";

const OurServicesPage = () => {
  return (
    <div className="our-services-page">
      <Service />
    </div>
  );
};

export default OurServicesPage;
