import { useState } from "react";
import { useLocation } from "react-router-dom";
import { formatFirstLettertoUpperCase } from "../../funcs";
import { services } from "../Misc/Data";
import YoutubeVideo from "../Home/YoutubeVideo";
import { pdfjs } from "react-pdf";
import Newsletter from "./Newsletter";
import Pagination from "./Pagination";
import Quote from "./Quote";
import "./OurServices.css";

const Service = () => {
  const { pathname } = useLocation();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const service = services.find(
    (service) =>
      service.service === formatFirstLettertoUpperCase(pathname.split("/")[2])
  );

  return (
    <div>
      <div className="service-header">{service?.service}</div>
      <div className="service-synopsis">
        <div>
          {service?.synopsis}
          {service?.service === "Residential" && "If interested, please use "}
          {service?.service === "Residential" && (
            // eslint-disable-next-line
            <a
              className="link"
              onClick={() => {
                window.open("https://app.solstice.us/register?r=NIMEC");
              }}
            >
              this link
            </a>
          )}
          {service?.service === "Residential" && " to sign up."}
        </div>
      </div>
      {service?.service === "Residential" && (
        <h2 className="youtube-video-header">
          Learn more about Community Solar from ComEd:
        </h2>
      )}
      {service?.service === "Residential" && <YoutubeVideo />}
      <div className="service-newsletter-cont">
        <h2> NIMEC Newsletter</h2>

        <Newsletter
          service={service}
          pageNumber={pageNumber}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
        />
      </div>

      <Pagination
        pageNumber={pageNumber}
        numPages={numPages}
        previousPage={previousPage}
        nextPage={nextPage}
      />

      <Quote />
    </div>
  );
};

export default Service;
