import skyline from "../Misc/Images/skyline.png";
import { clients, missionStatement } from "../Misc/Data";
import LinkBox from "./LinkBox";
import YoutubeVideo from "./YoutubeVideo";
import Slideshow from "./Slideshow";
import Footer from "../Footer";
import "./Home.css";

const HomePage = () => {
  const boxes = [
    { text: "Memberships", link: "/memberships" },
    { text: "Municipal Aggregation FAQ", link: "/faqs" },
    { text: "About Us", link: "/about_us" },
  ];

  return (
    <div className="home-page">
      <img src={skyline} className="skyline" alt="skyline" />

      <h1 className="statement">
        NIMEC is a collaborative of {clients.length} municipal and non-profit
        organizations that band together to drive down pricing.
      </h1>

      <div className="synopsis-cont">
        <div className="synopsis1">{missionStatement}</div>
      </div>

      <div className="link-box-outside">
        {boxes.map((box) => (
          <LinkBox key={box.text} box={box} />
        ))}
      </div>

      <h2 className="youtube-video-header-home-page">
        Learn more about Community Solar from ComEd:
      </h2>

      <YoutubeVideo />

      <Slideshow clients={clients} />

      <Footer />
    </div>
  );
};

export default HomePage;
