import { useLocation } from "react-router-dom";

const Youtube_Video = () => {
  const { pathname } = useLocation();

  return (
    <div
      className={`video-responsive${
        (pathname && pathname === "/home") || pathname === "/"
          ? "-home-page"
          : ""
      }`}
    >
      <iframe
        src="https://www.youtube.com/embed/F4Kybq8U3nE"
        frameBorder="0"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default Youtube_Video;
