import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import QuoteImg from "@mui/icons-material/FormatQuote";
import { monitorScreenWidth, getScreenWidth } from "../../funcs";

const Quote = () => {
  const { pathname } = useLocation();

  const [isMobileView, setIsMobileView] = useState(getScreenWidth("max", 65));

  useEffect(() => {
    monitorScreenWidth("max", 65, setIsMobileView);
  }, []);

  const servicePage = pathname.split("/")[2];

  const quotes = [
    {
      organization: "municipalities",
      name: "Wood Dale, IL",
      title: "City Administrator",
      quote:
        "The experience working with NIMEC has been smooth sailing; the knowledge base of electric aggregation that was shared with the City was instrumental to the program's success. As this was a big change for the community and a large-scale program, NIMEC was up to the task at hand and always went above and beyond to assist not only City Staff but also residents that inquired about the program.",
    },
    {
      organization: "commercial",
      name: "Elburn, IL",
      title: "City Administrator",
      quote:
        "NIMEC is a very professional company to work with. They walked the Village through the aggregation process and met personally with the Village along the way. Their promptness, organization and detail were exemplary.",
    },
  ];

  const quoteToUse = quotes.find(
    (quote) => quote.organization === servicePage && servicePage
  );

  return (
    <div className="single-quote-service-page">
      {quoteToUse && (
        <div>
          <QuoteImg
            fontSize={`${isMobileView ? "large" : "medium"}`}
            sx={{
              color: "rgb(78, 73, 73)",
            }}
          />
          <div className="quote-service-page">
            {quoteToUse.quote}
            <p className="quotee-service-page">
              - {quoteToUse.title} @ {quoteToUse.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quote;
