import { useState, useEffect } from "react";
import { monitorScreenWidth, getScreenWidth } from "../../funcs";
import Answer from "./Answer";
import Closed from "@mui/icons-material/Add";
import Open from "@mui/icons-material/Remove";

const SingleFAQ = ({ faq }) => {
  const [isMobileView, setIsMobileView] = useState(getScreenWidth("max", 65));
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    monitorScreenWidth("max", 65, setIsMobileView);
  }, []);

  return (
    <div className="faq-single-cont">
      <div
        className="faq-single-q-cont"
        onClick={() => setShowAnswer(!showAnswer)}
      >
        <div className="row-cont rc-q">
          <div className="q">{faq.Q}</div>
        </div>
        {showAnswer ? (
          <Open sx={{ fontSize: isMobileView ? 40 : 30 }} />
        ) : (
          <Closed sx={{ fontSize: isMobileView ? 40 : 30 }} />
        )}
      </div>
      {showAnswer && <Answer faq={faq} />}
    </div>
  );
};

export default SingleFAQ;
