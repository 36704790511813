import { FAQs } from "../Misc/Data";
import SingleFAQ from "./SingleFAQ";
import "./FAQs.css";

const FAQsPage = () => {
  const FAQList = FAQs.sort((a, b) => a.order - b.order);

  return (
    <div className="faqs-page">
      <div className="faq-full-cont">
        {FAQList.map((faq, idx) => (
          <SingleFAQ key={idx} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default FAQsPage;
