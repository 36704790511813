import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatURL } from "../../../funcs";

const List_Route_C = ({ route }) => {
  const { pathname } = useLocation();

  const [dropdown, setDropdown] = useState(false);

  const mainPage = `/${pathname.split("/")[1]}`;

  const currentPage = `/${pathname.split("/")[2]}`;

  useEffect(() => {
    if (mainPage === "/our_services") setDropdown(true);
  }, []);

  useEffect(() => {
    if (mainPage !== "/our_services") setDropdown(false);
  }, [mainPage]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    if (mainPage !== "/our_services") setDropdown(false);
  };

  const options = ["Municipalities", "Commercial", "Residential"];

  return (
    <div
      className={`navbar-route-row ${
        mainPage === route.path
          ? "selected"
          : mainPage === "/our_services" && route.path === "/our_services"
          ? "selected"
          : "not-selected"
      }`}
    >
      {route?.title === "Our Services" ? (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="services-row-comp-outside"
        >
          <div className="services-row-comp-main-row">
            {route.icon}
            <div className="navbar-text">{route.title}</div>
          </div>
          {dropdown && (
            <div
              className="dropdown-cont-comp"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {options.map((option, idx) => (
                <div key={idx}>
                  <Link
                    to={`/our_services/${formatURL(option)}`}
                    className={`services-dropdown-row white-text ${
                      currentPage === `/${formatURL(option)}`
                        ? "selected"
                        : "not-selected"
                    }`}
                  >
                    {option}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Link to={route.path} onClick={() => setDropdown(false)}>
          {route.icon}
          <div className="navbar-text">{route.title}</div>
        </Link>
      )}
    </div>
  );
};

export default List_Route_C;
