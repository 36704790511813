import { convertToUrlLink } from "../../funcs";

const Answer = ({ faq }) => {
  return (
    <div className="row-cont rc-a">
      {faq?.linkNeeded ? (
        convertToUrlLink(faq, "A")
      ) : (
        <div className="a">{faq.A}</div>
      )}
    </div>
  );
};

export default Answer;
