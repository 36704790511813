const MemberColumn = ({ clientList }) => {
  return (
    <div className="membs-column">
      {clientList &&
        clientList.map((client, idx) => (
          <div
            key={idx}
            className={`${
              idx === clientList.length - 1 ? "last-item-in-column" : ""
            }`}
          >
            {client.name} {client.municipalAgg && "*"}
          </div>
        ))}
    </div>
  );
};

export default MemberColumn;
