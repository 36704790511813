import { Link, useLocation } from "react-router-dom";
import { EEs } from "../../Misc/Data";
import David from "../../Misc/Images/david.png";
import Adam from "../../Misc/Images/adam.png";
import Chad from "../../Misc/Images/chad.png";

const EESinglePage = () => {
  const { pathname } = useLocation();

  const path = Number(pathname.split("/about_us/")[1]);

  const ee = EEs.find((ee) => ee.order === path);

  let pic;

  //eslint - disable - next - line;
  switch (ee?.name) {
    case "David Hoover":
      pic = David;
      break;
    case "Adam Hoover":
      pic = Adam;
      break;
    case "Chad Oldham":
      pic = Chad;
      break;
  }

  return (
    <div className="single-ee-page-mobile">
      <Link
        to="/about_us"
        className="about-us-mobile-header-mobile-single-ee-page"
      >
        Take me back to the full Nimec Team
      </Link>
      <div className="single-ee-cont-mobile">
        <div className="general-info-cont-single-mobile white-text">
          <img src={pic} className="photo-mobile" alt="photo" />
          <h1>{ee.name}</h1>
          <h2>{ee.title}</h2>
          <h4>{ee.email}</h4>
          <h4>{ee.phone}</h4>
        </div>
        <div className="about-me-cont-mobile">{ee.synopsis}</div>
      </div>
    </div>
  );
};

export default EESinglePage;
