export const findReferences = (arr) => {
  return arr.filter((client) => client.references.length);
};

export const formatFirstLettertoUpperCase = (word) => {
  return word.split("").reduce((a, letter, idx) => {
    if (idx === 0) {
      letter = letter.toUpperCase();
    }
    a += letter;
    return a;
  }, "");
};

export const monitorScreenWidth = (str, num, set) => {
  // return window.matchMedia("(max-width: 70em)").matches;
  return window
    .matchMedia(`(${str}-width: ${num}em)`)
    .addEventListener("change", (e) => set(e.matches));
};

export const getScreenWidth = (str, num) => {
  // return window.matchMedia("(max-width: 70em)").matches;
  return window.matchMedia(`(${str}-width: ${num}em)`).matches;
};

// export const getScreenWidth = (str, num) => {
//   return getMediaQuery({ query: `(${str}-width: ${num}em)` });
// };

export const formatURL = (str) => {
  const space = /\s/g;
  const period = /\./g;

  return str.toLowerCase().replaceAll(space, "_").replaceAll(period, "");
};

export const convertToUrlLink = (obj, key) => {
  const phraseFind = obj.linkWord;
  const idxStart = obj[key].indexOf(phraseFind);
  const idxEnd = idxStart + phraseFind.length;

  const first = obj[key].slice(0, idxStart);
  const phrase = obj[key].slice(idxStart, idxEnd);
  const second = obj[key].slice(idxEnd);

  return (
    <div>
      {first}
      <a
        className="link"
        onClick={() => {
          window.open(obj.link);
        }}
      >
        {phrase}
      </a>
      {second}
    </div>
  );
};
