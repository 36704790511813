import { useState, useEffect } from "react";
import { monitorScreenWidth, getScreenWidth } from "../../funcs";
import Logo from "./Logo";
import NavbarRoutesC from "./Comp/NavbarRoutesC";
import NavbarRoutesM from "./Mobile/NavbarRoutesM";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileView, setIsMobileView] = useState(getScreenWidth("max", 70));

  useEffect(() => {
    monitorScreenWidth("max", 70, setIsMobileView);
  }, []);

  return (
    <div className="navbar-cont">
      <Logo />

      {isMobileView ? <NavbarRoutesM /> : <NavbarRoutesC />}
    </div>
  );
};

export default Navbar;
