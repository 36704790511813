const Footer = () => {
  const inputs = [
    { key: "PHONE", value: "(847)-732-2515" },
    { key: "EMAIL", value: "ahoover@nimec.net" },
  ];

  return (
    <div className="navbar-footer">
      {inputs.map((input) => (
        <div key={input.key}>
          <div className="key-footer-navbar">{input.key}: </div>
          <div className="value-footer-navbar">{input.value}</div>
        </div>
      ))}
      <div className="copyright-navbar">© 2022 NIMEC. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
