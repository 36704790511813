import { Link } from "react-router-dom";
import { formatURL } from "../../../funcs";
import UpArrow from "@mui/icons-material/ArrowDropUp";
import DownArrow from "@mui/icons-material/ArrowDropDown";

const List_Route_M = ({
  route,
  closeMobileMenu,
  servicesDropdown,
  setServicesDropdown,
}) => {
  const path =
    route === "Municipal Aggregation FAQ"
      ? "faqs"
      : route === "Savings Comparison"
      ? "savings"
      : formatURL(route);

  const servicesClicked = () => {
    setServicesDropdown(!servicesDropdown);
  };

  const options = ["Municipalities", "Commercial", "Residential"];

  return (
    <li>
      {route === "Our Services" ? (
        <div
          onClick={() => servicesClicked()}
          className="dropdown-row-services-mobile dropdown-route-row"
        >
          <div>
            {route}
            {!servicesDropdown ? (
              <DownArrow sx={{ color: "rgb(215, 209, 209)", fontSize: 60 }} />
            ) : (
              <UpArrow sx={{ color: "rgb(215, 209, 209)", fontSize: 60 }} />
            )}
          </div>
        </div>
      ) : (
        <Link
          to={`/${path}`}
          className="dropdown-route-row"
          onClick={() => {
            closeMobileMenu();
          }}
        >
          {route}
        </Link>
      )}
      {servicesDropdown && route === "Our Services" && (
        <div className="dropdown-cont-our-services">
          {options.map((option, idx) => (
            <div
              key={idx}
              className={`${option !== "Residential" ? "dropdown-border" : ""}`}
            >
              <Link
                className="white-text service-single-option"
                to={`/our_services/${formatURL(option)}`}
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                {option}
              </Link>
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

export default List_Route_M;
