import { findReferences } from "../../funcs";
import Quote from "@mui/icons-material/FormatQuote";

const SlideShow = ({ clients }) => {
  let references = findReferences(clients).sort(
    (a, b) => b.references[0].quote.length - a.references[0].quote.length
  );

  return (
    <div className="slideshow">
      {references?.map((client, idx) => (
        <div key={idx}>
          <Quote
            fontSize="large"
            sx={{
              color: "rgb(78, 73, 73)",
            }}
          />
          {client.references.map((reference, idx) => (
            <div key={idx} className="quote">
              {reference.quote}
              <p className="quotee">
                - {reference.title} @ {client.name}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SlideShow;
