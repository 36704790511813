import { useState, useEffect } from "react";
import { monitorScreenWidth, getScreenWidth } from "../../funcs";
import { clients } from "../Misc/Data";
import MemberColumn from "./MemberColumn";
import Dropdown from "./Dropdown";
import "./Memberships.css";

const MembershipsPage = () => {
  const [selectedOrg, setSelectedOrg] = useState(null);

  let clientCount = 1;

  useEffect(() => {
    clientCount = 1;
  }, [selectedOrg]);

  const dropdownOrgs = Object.entries(
    clients
      .filter((client) => client.organization !== null)
      .map((client) => client.organization)
      .reduce((a, name) => {
        !a[name] ? (a[name] = 1) : a[name]++;

        return a;
      }, {})
  )
    .reduce((a, entry) => {
      const obj = {
        value: entry[0],
        label: `${entry[0]} (${entry[1]})`,
      };

      a.push(obj);

      return a;
    }, [])
    .sort((a, b) => {
      const fa = a.value.toLowerCase();
      const fb = b.value.toLowerCase();

      if (fa < fb) return -1;

      if (fa > fb) return 1;

      return 0;
    });

  const clients1 = clients.sort((a, b) => {
    const fa = a.name.toLowerCase();
    const fb = b.name.toLowerCase();

    if (fa < fb) return -1;

    if (fa > fb) return 1;

    return 0;
  });

  let targetClients;

  targetClients = clients1.filter((client) => client);

  if (selectedOrg) {
    targetClients = targetClients.filter(
      (client) => client.organization === selectedOrg.value
    );
  }

  targetClients = [targetClients];

  const reduceClients = (arr, num) => {
    let newArr = [];

    return arr.reduce((a, arr) => {
      arr.forEach((client, idx) => {
        if (clientCount <= num) {
          newArr.push(client);
          clientCount++;

          if (idx === arr.length - 1) {
            a.push(newArr);
          }
        } else {
          a.push(newArr);
          clientCount = 1;
          newArr = [];
        }
      });

      return a;
    }, []);
  };

  const organizeClients = (arr, num1, num2, num3, num4) => {
    //nums 1 & 2 make the number of clients listed in each column go up/down
    //nums 3 & 4 make the member column row count go up/down
    if (arr[0].length > num1) {
      if (arr[0].length > num2) {
        arr = reduceClients(arr, num3);
      } else {
        arr = reduceClients(arr, num4);
      }
    }

    return arr;
  };

  const [isMobileView, setIsMobileView] = useState(getScreenWidth("max", 65));

  useEffect(() => {
    monitorScreenWidth("max", 65, setIsMobileView);
  }, []);

  if (isMobileView) {
    targetClients = organizeClients(targetClients, 23, 130, 92, 63);
  } else {
    targetClients = organizeClients(targetClients, 23, 130, 61, 42);
  }

  return (
    <div className="membs-page">
      <div className="membs-dropdown">
        <Dropdown
          dropdownOrgs={dropdownOrgs}
          setSelectedOrg={setSelectedOrg}
          selectedOrg={selectedOrg}
          isMobileView={isMobileView}
        />
        <button onClick={() => setSelectedOrg(null)}>Clear Filter</button>
      </div>

      <div className="membs-cont-full">
        <div className="asterisk-cont">* Municpal Aggregation</div>

        <div className="membs-cont-outside">
          <div className="membs-cont-inside">
            <div className="membs-text-cont">
              {targetClients.map((column, idx) => (
                <MemberColumn key={idx} clientList={column} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipsPage;
