import { useState, useEffect, useRef } from "react";
import { monitorScreenWidth, getScreenWidth } from "../../../funcs";
import MenuItemIcon from "@mui/icons-material/List";
import ListRouteM from "./ListRouteM";

const NavbarRoutesM = () => {
  const [isTablet, setIsTablet] = useState(getScreenWidth("min", 65));
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [click, setClick] = useState(false);

  useEffect(() => {
    monitorScreenWidth("min", 65, setIsTablet);
  }, []);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    setServicesDropdown(false);
  };

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      const handlerClass = event.target.className;

      if (
        !ref.current.contains(event.target) &&
        handlerClass !== "dropdown-route-row" &&
        handlerClass !== "" &&
        !handlerClass.includes("css-18xcf4m-MuiSvgIcon-root") &&
        handlerClass.baseVal !== "" &&
        handlerClass !== "white-text service-single-option"
      ) {
        closeMobileMenu();
        setServicesDropdown(false);
      }

      if (
        handlerClass === "menu-icon" ||
        handlerClass.baseVal ===
          "MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1ha5wk2-MuiSvgIcon-root" ||
        handlerClass.baseVal === ""
      ) {
        setTimeout(() => {
          setServicesDropdown(false);
        }, 200);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [click]);

  const routes = [
    "Home",
    "Our Services",
    "Memberships",
    "Municipal Aggregation FAQ",
    "Savings Comparison",
    "About Us",
  ];

  return (
    <div>
      <div className="menu-icon" onClick={handleClick} ref={ref}>
        <MenuItemIcon sx={{ fontSize: isTablet ? 40 : 90 }} />
      </div>
      <ul className={click ? "dropdown-menu active" : "dropdown-menu"}>
        {routes.map((route, idx) => (
          <ListRouteM
            key={idx}
            route={route}
            closeMobileMenu={closeMobileMenu}
            servicesDropdown={servicesDropdown}
            setServicesDropdown={setServicesDropdown}
          />
        ))}
      </ul>
    </div>
  );
};

export default NavbarRoutesM;
