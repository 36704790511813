import Select from "react-select";

const MembershipsPage = ({
  dropdownOrgs,
  setSelectedOrg,
  selectedOrg,
  isMobileView,
}) => {
  const styles = {
    placeholder: (styles) => {
      return {
        ...styles,
        color: "rgb(4, 154, 204)",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: "rgb(4, 154, 204)",
        "&:hover": {
          color: "rgb(4, 154, 204)",
        },
      };
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        background: "rgb(4, 154, 204)",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        color: "rgb(4, 154, 204)",
      };
    },
    control: (styles) => {
      return {
        ...styles,
        background: "#101f33",
        color: "rgb(4, 154, 204)",
        border: "solid #101f33 1px",
        cursor: "pointer",
        width: `${isMobileView ? "25rem" : "16rem"}`,
        height: isMobileView ? 78 : 42,
        fontSize: `${isMobileView ? "1.8rem" : "1rem"}`,
        textAlign: "center",
        "&:hover": {
          border: "solid black 1px",
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        background: "white",
        color: "black",
        cursor: "pointer",
        width: `${isMobileView ? "24.5rem" : "15.5rem"}`,
        borderBottom: "solid lightGrey 1px",
        height: `${isMobileView ? "3.5rem" : "2.5rem"}`,
        fontSize: `${isMobileView ? "1.5rem" : "1rem"}`,
        textAlign: "center",
        "&:hover": {
          background: "rgb(242, 242, 234)",
        },
      };
    },
  };

  return (
    <Select
      options={dropdownOrgs.length && dropdownOrgs}
      onChange={(value) => setSelectedOrg(value)}
      styles={styles}
      isSearchable={false}
      value={selectedOrg}
      placeholder={"Filter by Organization"}
      className="mems-select"
    />
  );
};

export default MembershipsPage;
