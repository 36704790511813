import David from "../Misc/Images/david.png";
import Adam from "../Misc/Images/adam.png";
import Chad from "../Misc/Images/chad.png";

const EECont = ({ ee }) => {
  let pic;

  // eslint-disable-next-line
  switch (ee.name) {
    case "David Hoover":
      pic = David;
      break;
    case "Adam Hoover":
      pic = Adam;
      break;
    case "Chad Oldham":
      pic = Chad;
      break;
  }

  return (
    <div
      className={`single-ee-cont ${
        ee.name === "Chad Oldham" ? "seec-last" : ""
      }`}
    >
      <div className="general-info-cont white-text">
        <img src={pic} className="photo" alt="photo" />
        <h1>{ee.name}</h1>
        <h2>{ee.title}</h2>
        <h4>{ee.email}</h4>
        <h4>{ee.phone}</h4>
      </div>
      <div className="about-me-cont">
        <h1>About {ee.name.split(" ")[0]}</h1>
        <div>{ee.synopsis}</div>
      </div>
    </div>
  );
};

export default EECont;
