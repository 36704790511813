import Home from "@mui/icons-material/Home";
import WindPower from "@mui/icons-material/WindPower";
import HelpCenter from "@mui/icons-material/HelpCenter";
import Savings from "@mui/icons-material/Paid";
import AboutUs from "@mui/icons-material/EmojiPeople";
import Groups from "@mui/icons-material/Groups";
import ListRouteC from "./ListRouteC";
import Footer from "./Footer";

const NavbarRoutesC = () => {
  const color = "rgb(215, 209, 209)";

  const routes = [
    {
      title: "Home",
      icon: <Home sx={{ color }} />,
      path: "/home",
    },
    {
      title: "Our Services",
      icon: <WindPower sx={{ color }} />,
      path: "/our_services",
    },
    {
      title: "Memberships",
      icon: <Groups sx={{ color }} />,
      path: "/memberships",
    },
    {
      title: "Municipal Aggregation FAQ",
      icon: <HelpCenter sx={{ color }} />,
      path: "/faqs",
    },
    {
      title: "Savings Comparison",
      icon: <Savings sx={{ color }} />,
      path: "/savings",
    },
    {
      title: "About Us",
      icon: <AboutUs sx={{ color }} />,
      path: "/about_us",
    },
  ];

  return (
    <div className="navbar-main-cont">
      <div>
        {routes.map((route, idx) => (
          <ListRouteC key={idx} route={route} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default NavbarRoutesC;
