const Footer = () => {
  const inputs = [
    { key: "PHONE", value: "(847)-732-2515" },
    { key: "EMAIL", value: "ahoover@nimec.net" },
  ];

  return (
    <div className="footer-cont">
      <div className="company-info-footer">
        {inputs.map((input) => (
          <div key={input.key}>
            <div className="key-footer">{input.key}: </div>
            <div className="value-footer">{input.value}</div>
          </div>
        ))}
      </div>

      <div className="copyright">© 2022 NIMEC. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
