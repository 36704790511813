import { Link } from "react-router-dom";
import David from "../../Misc/Images/david.png";
import Adam from "../../Misc/Images/adam.png";
import Chad from "../../Misc/Images/chad.png";

const MobilePage = ({ ee }) => {
  let pic;

  // eslint-disable-next-line
  switch (ee.name) {
    case "David Hoover":
      pic = David;
      break;
    case "Adam Hoover":
      pic = Adam;
      break;
    case "Chad Oldham":
      pic = Chad;
      break;
  }

  return (
    <Link to={`/about_us/${ee?.order}`} className="single-ee-cont">
      <div className="general-info-cont white-text">
        <img src={pic} className="photo" alt="photo" />
        <div>
          <h1>{ee.name}</h1>
          <h2>{ee.title}</h2>
          <h4>{ee.email}</h4>
          <h4>{ee.phone}</h4>
        </div>
      </div>
    </Link>
  );
};

export default MobilePage;
